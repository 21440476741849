<template>
	<div class="Sendcar-box" :class="themeClass" v-loading="sendCarloading">
		<div class="flex-row margin-b-5 margin-t-5">
			<!-- 您的位置： -->
			<!-- <breadcrumb></breadcrumb> -->
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item v-for="(item,index) in breadList" :key="index" :to="{ path: item.path }">
					{{item.meta.title}}
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="margin-t-10 flex-row-space-between">
			<div class="SendCar-title padding-tb-20 padding-lr-20 flex-colum radius10">
				<!-- <p>可派车辆 （吨）</p>
				<p>{{ (orderInfo.fPlanAmount - orderInfo.fScheduleVanAmount).toFixed(2) }}</p> -->
				<div class="flex-row-space-between margin-b-10">
					<div><span style="font-weight: bold;">订单号：</span><span>{{orderInfo.fOrderNumber}}</span></div>
					<div><span style="font-weight: bold;">截止日期：</span><span>{{orderInfo.fEndDate}}</span></div>
				</div>
				<div class="flex-row-center-center color-theme margin-t-20">
					<div class="text-center flex-colum width-120">
						<div class="margin-b-5">
							<span class="font-size20 font-weight700">{{orderInfo.fPlanAmount}}</span>{{orderInfo.fUnitName}}
						</div>
						<div>
							订单量
						</div>
					</div>
					<div class="text-center flex-colum width-120">
						<div class="margin-b-5">
							<span class="font-size20 font-weight700">{{orderInfo.fScheduleVanAmount}}</span>{{orderInfo.fUnitName}}
						</div>
						<div>
							已派车量
						</div>
					</div>
					<div class="text-center flex-colum width-120">
						<div class="margin-b-5">
							<span class="font-size20 font-weight700">{{orderInfo.fReceivedAmount}}</span>{{orderInfo.fUnitName}}
						</div>
						<div>
							提货量
						</div>
					</div>
					<div class="text-center flex-colum width-120">
						<div class="margin-b-5">
							<span class="font-size20 font-weight700">{{orderInfo.sendCarAmount}}</span>{{orderInfo.fUnitName}}
						</div>
						<div>
							可派车量
						</div>
					</div>
				</div>
			</div>
			<div class="Sendcar-right padding-tb-20 padding-lr-20 flex-colum radius10 flex-row">
				<div>
					<div  class="flex-row">
						<div class="margin-b-5  hide-text" style="font-weight: bold;width: 70px;display: flex; justify-content: space-between;">
							<div>商</div>
							<div>品：</div>
						</div>
						<div class="margin-b-5 width-505 hide-text">{{ orderInfo.fGoodsFullName }}</div>
					</div>
					<div class="flex-row">
						<div class="margin-b-5  hide-text" style="font-weight: bold;width: 70px;display: flex; justify-content: space-between;">
							<div>收</div>
							<div>货</div>
							<div>人：</div>
						</div>
						<div class="margin-b-5 width-505 hide-text"> <span class="margin-r-10">{{ orderInfo.fConsignee }}</span>{{ orderInfo.fConsigneePhone }}</div>
					</div>
					<div class="flex-row">
						<div class="margin-b-5  hide-text" style="font-weight: bold;width: 70px;display: flex; justify-content: space-between;">
							<div>工</div>
							<div>程：</div>
						</div>
						<div class="margin-b-5 width-505 hide-text">{{ orderInfo.fProjectName }}</div>
					</div>
					<div class="flex-row">
						<div class="margin-b-5  hide-text" style="font-weight: bold;width: 70px;display: flex; justify-content: space-between;">
							<div>地</div>
							<div>址：</div>
						</div>
						<div class="margin-b-5 width-505 hide-text">{{ orderInfo.fDeliveryAddress }}</div>
					</div>
					<div class="flex-row">
						<div class="margin-b-5  hide-text" style="font-weight: bold;width: 70px;display: flex; justify-content: space-between;">
							<div>运</div>
							<div>输</div>
							<div>方</div>
							<div>式：</div>
						</div>
						<div class="margin-b-5 width-505 hide-text">{{ orderInfo.fBuTypeName }}</div>
					</div>
				</div>
			</div>
		</div>
		
		   <div  class="flex-row-space-between margin-t-10 margin-b-10">
		     <div class="flex-row" style="width: 540px;border: 1px ;line-height: 35px;">
				 <div class="SensCar-check">选择车辆</div>
				 <div v-if="showDevice" style="width:425px;line-height: 32px;font-size:13px">
				    <span >定向送达活动使用设备类型：</span><span style="font-weight: bold;color:#0173FE;">{{fIsHdTitle === 0 ? 'GPS设备' :'汉德设备'}}</span>
				 </div>
			 </div>
			  <div class="flex-row margin-b--5" style="width: 540px;">
				 <span style="width: 60px;line-height: 30px;font-size: 15px;font-weight: 600;">工程：</span>
				 <el-input size="small"  placeholder="请输入工程" class="output" v-model="fProjectName"  ></el-input>
			  </div>
		   </div>
			<div  v-if="showByOderId" class="margin-l-10 margin-b-10" >
			  <span>定向送达活动中</span>
			  <span class="OrderText">使用GPS设备的车辆在抵达目的地后需上传车辆信息</span>
			  <span>，未上传信息的派车单将无法赠送积分(使用汉德设备无需上传)。</span>
			  <a style="color:#0173FE;OrderA" target="_blank" href="https://www.xjjcjc.com/helpcenter/FenceArrive.html">(点击查看GPS设备车辆上传操作流程)</a>
			</div>
		
		<div class="sendcar-bottom">
			<div class="sendcar-conone" v-if="chooseConfirmCar.length < 1">
				<div class="flex-row-center-center">
					<svg-icon icon-class="NoDelivery" style="width: 200px;height: 190px;" />
				</div>
				<p class="margin-b-10">目前暂无车辆</p>
				<div class="flex-row-center-center">
					<!-- <div class="border-color-theme color-theme text-center btn-box margin-r-10 pointer"> -->
					<!-- 导入Excel -->
					<div class="border-color-theme color-theme text-center btn-box pointer margin-r-10" @click="downExcel">下载模板</div>
					<uploadExcel @success="getDataSu" v-model="fileList" :rules="fileRules" :uploadToken="dataUpdoce" />
					<!-- </div> -->
					<div class="border-color-theme color-theme text-center btn-box pointer" @click="dialogChsCar = true">选择车辆</div>
				</div>
			</div>
			<div class="sendcar-contwo" v-else>
				<div class="flex-row-between-wrap">
					<div v-for="(item, index) of chooseConfirmCar" :key="index" class="send-car-item flex-column-center-center margin-b-10">
						<div class="width100 flex-row-space-between-center">
							<div class="flex-row-align-center font-size13">
								<div class="margin-r-20 font-weight700">{{item.fPlateNumber}}</div>
								<div class="color-theme">{{item.fTransportToolType}}</div>
							</div>
							<div><i class="el-icon-delete deletIconBtn" @click="deleteChoose(item)"></i></div>
						</div>
						<div class="width100 flex-colum-between margin-t-5" style="white-space: nowrap;">
							<div class="flex padding-6">
								<div class="flex">
									<span>
										
									司机：
									</span>
									<el-input v-model="item.fLinkMan" placeholder="请输入内容" style="width: 60%;"></el-input>
								</div>
								<div class="flex">
									<span style="width: 40%; text-align: center;">
										
									电话：
									</span>
									<el-input v-model="item.fLinkManTelCode" placeholder="请输入内容" style="width: 100%;"></el-input>
								</div>
							</div>
							<div class="flex">
								<div class="flex">
									<span>
										
									派车量(吨)：
									</span>
									<el-input v-model.float="item.fMaximumLoad" @input="fMaximumLoadInput(item.fMaximumLoad,index)"
									 @change="fMaximumLoadChange(item.fMaximumLoad,index)" style="width: 60%;"></el-input>
								</div>
								<div class="flex">
									<span style="width: 40%; text-align: center;">
										
									趟次：
									</span>
									<el-input v-model.float="item.fFrequency" @input="fFrequencyInput(item.fFrequency,index)"
									 @change="fFrequencyChange(item.fFrequency,index)" style="width: 100%;"></el-input>
								</div>
							</div>
							
							
						</div>
					</div>
					<!-- 填充元素 -->
					<div class="send-car-item-fill" v-if="chooseConfirmCar.length % 3 != 0"></div>
				</div>
			</div>
		</div>
		<!-- <div class="Sendcar-footer pointer" v-if="chooseConfirmCar.length < 1">
			<div @click="dialogChsCar = true">选择车辆</div>
		</div> -->
		<div class="Sendcar-footerTwo flex-row-space-between-center padding-lr-20" v-if="chooseConfirmCar.length >= 1">
			<div>
				<p class="pointer-color-theme" @click="chooseConfirmCar = []">全部清空</p>
			</div>
			<div class="flex-row-align-center">
				<p class="margin-r-10 font-color-FF9900 font-size15" >已选{{chooseConfirmCar.length < 10 ? '0' + chooseConfirmCar.length : chooseConfirmCar.length }}辆，预计派车量：{{ carWeight }}吨</p>
				<div class="background-color-theme color-theme text-center btn-box1 pointer font-size15" @click="sendCarSubmit">派车</div>
			</div>
		</div>
		<!-- 选择车辆弹框 -->
		<el-dialog v-dialogDrag v-if="dialogChsCar" title="请选择车辆" :visible.sync="dialogChsCar" width="700px"
		 :close-on-press-escape="false" :close-on-click-modal="false" v-loading="loading">
			<div class="top-box flex-row-align-center">
				<div @click="dialogAddCar = true;" class="add-car-btn margin-r-10 background-color-theme flex-row-center-center font-size13">
					新增车辆
				</div>
				<div style="padding-right: 5px; width: 50%">
					<el-select multiple collapse-tags @change="selectAdd" v-model="carToolType" class="selectBox" clearable placeholder="请选择">
						<el-option  v-for="item in carToolList" :key="item.value" :label="item.key"
							:value="item.value" :disabled="item.disabled">
						</el-option>
					</el-select>
				</div>
				<el-input placeholder="请输入车牌号进行模糊查询" prefix-icon="el-icon-search" v-model="searchText" @input="filter(searchText)" />
			</div>
			<el-table :data="transporttool.slice((currentPage - 1) * pagesize, currentPage * pagesize)" @selection-change="handleSelectionChange"
			 @select-all="handleSelectionAll" border style="width: 100%; margin-top:5px;" height="500px">
				<el-table-column type="selection" fixed width="50" align="center" />
				<el-table-column prop="fPlateNumber" label="车牌号"></el-table-column>
				<el-table-column prop="fTransportToolPlateColor" label="牌照颜色" width="100" align="center"show-overflow-tooltip></el-table-column>
				<el-table-column prop="fLinkMan" label="司机"></el-table-column>
				<el-table-column prop="fLinkManTelCode" label="电话"></el-table-column>
				<el-table-column prop="fGroupName" label="分组"></el-table-column>
				<el-table-column prop="fTransportToolType" label="类型"></el-table-column>
				<el-table-column prop="fMaximumLoad" label="载货量（净重）" width="100"></el-table-column>
			</el-table>
			<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="currentPage"
			 :pagesize="pagesize" :total="total" class="margin-t-10">
			</pagination>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogChsCar = false" class="table-btn" style="">取 消</el-button>
				<el-button @click="chooseConfirm" class="table-btn background-color-theme">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 新增车辆弹框 -->
		<el-dialog v-dialogDrag v-if="dialogAddCar" title="新增车辆" :visible.sync="dialogAddCar" width="600px"
		 :close-on-press-escape="false" :close-on-click-modal="false" @close="hiddClick">
			<el-form :model="addCarForm" :rules="rules" ref="addCarForm" label-width="130px">
				<el-form-item label="类型" prop="carToolType">
					<el-select filterable  v-model="addCarForm.carToolType" clearable placeholder="请选择">
						<el-option @click.native="CarToolTypechange(item)" v-for="item in carToolgroupList" :key="item.fTransportToolTypeID" :label="item.fTransportToolType" :value="item.fTransportToolTypeID">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="车号/船号" prop="fPlateNumber">
					<el-input v-model="addCarForm.fPlateNumber" placeholder="请输入车号/船号" @blur="blurInput"></el-input>
				</el-form-item>
				<el-form-item label="牌照颜色" prop="TransportToolPlateColor">
					<el-select v-model="addCarForm.TransportToolPlateColor" clearable placeholder="请选择牌照颜色">
						<el-option v-for="item in TransportToolPlateColorList" :key="item.id" 
							:label="item.fTransportToolPlateColor" :value="item.fTransportToolPlateColorID">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="司机" prop="fLinkMan">
					<el-input v-model="addCarForm.fLinkMan" placeholder="请输入司机姓名"></el-input>
				</el-form-item>
				<el-form-item label="电话" prop="fLinkManTelCode">
					<el-input v-model="addCarForm.fLinkManTelCode" placeholder="请输入电话号码"></el-input>
				</el-form-item>
				<el-form-item label="载货量/净重(吨)" prop="fMaximumLoad">
					<el-input v-model.number="addCarForm.fMaximumLoad" placeholder="请输入载货量/净重"></el-input>
				</el-form-item>
				<el-form-item label="分组" prop="carGroupType">
					<el-select filterable allow-create default-first-option :filter-method="getAddType" v-model="addCarForm.carGroupType" clearable placeholder="请选择">
						<el-option v-for="item in carGroupList" :key="item.value" :label="item.key" :value="item.value">
						</el-option>
					</el-select>
					<div class="font-size12 font-color-FF9900">
						请选择需要分配的车辆分组或输入新的分组。
					</div>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="hiddClick" :disabled="disabledAddCar" style="width:80px;height:30px;">取 消</el-button>
				<el-button @click="submitForm" :disabled="disabledAddCar" :loading="disabledAddCar" class="background-color-theme" style="width:80px;height:30px;">确
					定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import breadcrumb from '@/components/breadcrumb.vue'
	import uploadExcel from "@/components/upload/uploadExcel";
	import pagination from '@/components/pagination/pagination.vue'
	export default {
		components: {
			breadcrumb,
			uploadExcel,
			pagination
		},
		data() {
			let validateInput = (rule, value, callback) => {
				if(this.selectval.fTransportToolClassID == 1 ){
					//这里是双判断 原因是 我要截取第一个字是交警12123的汉字
					console.log( /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领]{1}$/.test(value.charAt(0)),'測試')
					if(value.length >= this.carrule+1 && this.isVehicleNumber(value) && /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领]{1}$/.test(value.charAt(0))){
						callback();
					}else{
						callback(new Error("请输入正确的车牌号，除首位汉字外，车牌号只能包含数字或字母(不能包含字母I和O)"));
					}
				} else {
					if (!this.checkSpecialKey(value)) {
						callback(new Error("车船号只能输入汉字、数字和字母！"));
					} else {
						callback();
					}
				}
			};
			return {
				sendCarloading: false,
				disabledAddCar: false,
				fProjectName:"",  //工程的namme
				carrule:null,
				selectval:{},
		
				orderFIsContinue:0,//是否继续
				TransportToolPlateColorList:[],
				total: 0, //总条数
				pagesize: 20, //每页显示条目个数
				currentPage: 1, //当前页
				loading: false,
				dataUpdoce: {}, //上传文件的token
				fileList: [], //上传的文件
				fileRules: {}, //上传文件的规则
				breadList: [],
				// 订单ID
				orderID: '',
				// 订单信息
				orderInfo: {},
				// 派车单数据
				initData: {},
				// 查询车辆信息字段
				searchText: '',
				// 客商车辆信息
				transporttool: [],
				initTransporttool: [], //车辆原数据
				// 选择车辆模态窗显示变量
				dialogChsCar: false,
				// 已选择的车辆
				chooseCar: [],
				// 已选择确定的车辆
				chooseConfirmCar: [],
				// 添加车辆
				dialogAddCar: false,
				// 运输工具类型
				carToolList: [],
				oldChooseData : [],
				carToolgroupList : [],
				carToolgroupListType : '',
				addCarLoading: false,
				carToolType: [], //搜索类型
				carGroupList : [],
				carGroupType : '',
				addCarForm: {
					carToolType: '', //类型
					carGroupType : '',//分组
					fPlateNumber: '', //车号
					fLinkMan: '', //司机
					TransportToolPlateColor: 2,//车牌颜色
					fLinkManTelCode: '',
					fMaximumLoad: '',
					fFrequency : '',
					carGroupList : []
				},
				cargrouplList:[],//车辆分组的类别
				rules: {
					carGroupType:[
						{
							message: '请选择分组',
							trigger: 'blur'
						}
					],
					carToolType: [{
						required: true,
						message: '请选择类型',
						trigger: 'blur'
					}],
					TransportToolPlateColor:[{
						required: true,
						message: '请选择牌照颜色',
						trigger: 'blur'
					}],
					fPlateNumber: [{
							required: true,
							message: '请输入车号/船号',
							trigger: 'change'
						},
						{
							validator: validateInput,
							trigger: 'blur'
						}
					],
					fLinkMan: [{
						required: true,
						message: '请输入司机姓名',
						trigger: 'change'
					}],
					fLinkManTelCode: [{
						required: true,
						validator: (rule, value, callback) => {
							if (value === '') {
								callback(new Error('请输入手机号'));
							} else {
								let phoneNumberRul = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
								if (phoneNumberRul.test(value)) {
									callback();
								} else {
									callback(new Error('请输入正确的手机号码'));
								}
							}
						},
						trigger: 'blur'
					}],
					fMaximumLoad: [
						// {
						// 	validator: (rule, value, callback) => {
						// 		if (!value) {
						// 			return callback(new Error('重量不能为空'));
						// 		}
						// 		setTimeout(() => {
						// 			if (value < 10) {
						// 				callback(new Error('重量必须大于10'));
						// 			} else {
						// 				callback();
						// 			}

						// 		}, 1000);
						// 	},
						// 	required: true,
						// 	trigger: 'change'
						// },
						{
							required: true,
							message: '请输入载货量/净重',
							trigger: 'change'
						},
						{
							type: 'number',
							message: '载货量/净重必须为数字'
						}
					],
				},
				minAmount:'',//运输最小量
				showByOderId:false,
				showDevice: false,
				fIsHdTitle:""
			};
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			carWeight() {
				let num = 0
				this.chooseConfirmCar.forEach(item => {
					let count = item.fFrequency
					if (!item.fMaximumLoad) {
						item.fMaximumLoad = 0;
					}
					if (!count) {
						count = 0;
					}
					// num = num + parseFloat(item.fMaximumLoad) * count
					num = (num*1000 + ((parseFloat(item.fMaximumLoad)*1000)*(parseInt(count)*1000))/1000000*1000)/1000
				})
				return num;
			}
		},
		watch: {
			// carToolType(curVal, oldVal) {
			// 	console.log(curVal,oldVal)
			// 	if (curVal == '') {
			// 		this.carToolType = [];
			// 	}
			// 	this.getTransporttoolList();
			// },
			dialogAddCar(){
				this.getTransportgroupList()
				this.getgroupTypes()
			},
		},
		async mounted() {
      //车辆号码验证长度
      this.getcarrule()
			let data = [{
				path: this.$route.path,
				meta: {
					title: this.$route.meta.title
				}
			}];
			this.breadList = [{
				path: this.$route.query.detailspath,
				meta: {
					title: this.$route.query.detailsname
				}
			}].concat(data);
			this.orderID = await this.$route.query.id;
			// 获取订单信息
			this.getOrderInfo();
			// 派车初始化接口
			this.deliverOrderInit();
			// 派车车辆查询
			this.getTransporttoolList();
			//查询所有启用运输工具类型
			// this.getTransportToolType();
			//查询所有启用运输工具类型
			// this.getTransportToolType();
			//查询上传excel规则
			this.getRule();
			//查询所有的车辆分组类别
			this.getgroupType();
			// this.getTransportToolgroupType()
			this.acquireAmount();
			//车牌颜色
			this.getTransportToolcolor()
			this.getByOderId()
		},
		
		// async activated() {
		// 	this.orderID = await this.$store.getters.getCommonData.id;
		// 	// 获取订单信息
		// 	this.getOrderInfo();
		// 	// 派车初始化接口
		// 	this.deliverOrderInit();
		// 	// 派车车辆查询
		// 	this.getTransporttoolList();
		// 	// 查询所有启用运输工具类型
		// 	this.getTransportToolType();
		// },
		methods: {
      //车辆号码验证长度
      getcarrule(){
				this.ApiRequestPost('/api/mall/ebbase/para-value/get-plate-number-min-length', {}).then(
					result => {
						this.carrule= result
            console.log(this.carrule,'车辆验证长度')
					},
					rej => {}
				);
      },
      CarToolTypechange(val){
       this.selectval=val
      },
      isVehicleNumber (vehicleNumber) {
       let answer = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领学警港澳][0-9a-hj-np-zA-HJ-NP-Z]{5,}$/.test(vehicleNumber)
       console.log(answer)
       return answer
      },
		   // 查询是否显示提示
		   getByOderId(){
			   this.ApiRequestPostNOMess('api/mall/ebmonitor/monitor-order/get-by-orderId',{
				   id:this.orderID
			   }).then(result=>{
				   if(result.obj != null ){
					   this.showDevice =true
					   if(result.obj.fMonitorOrderID != '-1' && result.obj.fIsHd ===0){
						   this.showByOderId =true
						   this.fIsHdTitle=result.obj.fIsHd
					   }  
				   }else{
					   this.showByOderId =false
					   this.showDevice =false;
				   }
			   })
		   },
			   
			//查询所有启用颜色
			getTransportToolcolor() {
				this.ApiRequestPost('api/mall/ebbase/transportToolPlate-color/get-enable-list', {}).then(
					result => {
						this.TransportToolPlateColorList = result.obj.items;
						if(result.obj.items.length !=0){
							result.obj.items.forEach((item,index)=>{
								if(item.fTransportToolPlateColor ==='黄色'){
									this.addCarForm.TransportToolPlateColor=item.fTransportToolPlateColorID
								}
							})
						}
					},
					rej => {}
				);
			},
			//模糊搜索前端过滤
			filter(val) {
				if (val) {
					this.transporttool = this.initTransporttool.filter(item => item.fPlateNumber.indexOf(val) != -1);
				} else {
					this.transporttool = this.initTransporttool;
				}
				
				this.total = this.transporttool.length
				this.currentPage = 1
			},
			getAddType(e){
				this.addCarForm.carGroupType = e
				//输入框的值发生改变时触发
				console.log(this.addCarForm.carGroupType)
			},
			//pageSize（每页个数） 改变时会触发
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			//currentPage（当前页数） 改变时会触发
			handleCurrentChange(val) {
				this.currentPage = val;
				console.log(`当前页: ${val}`);
				// this.getTransporttoolList();
			},
			// 派车车辆查询
			getTransporttoolList() {
				this.loading = true;
				console.log(this.carToolType)
				if(this.carToolType == -1){
					this.carToolType = ''
				}
				this.ApiRequestPost("api/mall/ebcustomer/transporttool/get-list-by-groupName", {
					filter: this.searchText,
					// fGroupID: this.carToolType,
					"fGroupName": this.carToolType,
					// maxResultCount: this.pagesize,
					// skipCount: (this.currentPage - 1) * this.pagesize
				}).then(res => {
					// this.carToolType =
					this.initTransporttool = res.obj.items;
					this.transporttool = JSON.parse(JSON.stringify(res.obj.items));
					this.total = res.obj.totalCount;
					this.loading = false;
				}).catch(err => {
			
				})
			},
			selectAdd(val){
				console.log(val)
				this.selectAll(val)
			},
			selectAll(val){
				const allValues = this.carToolList.map(item => {
					console.log(item.value)
					return item.value;
				}); 
				// 用来储存上一次选择的值，可进行对比
				const oldVal = this.oldChooseData.length > 0 ? this.oldChooseData : [];
						 
				  // 若选择全部
				  if (val.includes('全部')) {
					this.carToolType = allValues;
				  }
			 
				  // 取消全部选中， 上次有， 当前没有， 表示取消全选
				  if (oldVal.includes('全部') && !val.includes('全部')) {
					this.carToolType = [];
				  }
			 
				  // 点击非全部选中，需要排除全部选中 以及 当前点击的选项
				  // 新老数据都有全部选中
				  if (oldVal.includes('全部') && val.includes('全部')) {
					const index = val.indexOf('全部');
					val.splice(index, 1); // 排除全选选项
					this.carToolType = val;
				  }
			 
				  // 全选未选，但是其他选项都全部选上了，则全选选上
				  if (!oldVal.includes('全部') && !val.includes('全部')) {
					if (val.length === allValues.length - 1) {
					  this.carToolType = ['全部'].concat(val);
					}
				  }
				  console.log(this.carToolType)
				  this.getTransporttoolList(this.carGroupType)
				  // 储存当前选择的最后结果 作为下次的老数据
				  this.oldChooseData = this.carToolType
			},
			
			getTransportgroupList() {
				this.ApiRequestPost('api/mall/ebbase/transportTool-Type/get-onstatus-list', {}).then(
					result => {
						console.log('车辆信息列表===================',result)
						this.carToolgroupList = result.obj.items;
						console.log(this.carToolgroupList)
						if(this.carToolType == ""){
							this.carToolType = this.carToolgroupList[0].fTransportToolTypeID
						}
					},
					rej => {}
				);
			},
			//特殊字符验证
			checkSpecialKey(str) {
				let specialKey = "[`~!@#$^&*()=|{}':;',\\[\\].·<>/?~！@#￥……&*（）——_|{}【】‘；：”“'。，、？-+/*<>《》]";
				for (let i = 0; i < str.length; i++) {
					if (specialKey.indexOf(str.substr(i, 1)) != -1) {
						return false;
					}
				}
				return true;
			},
			//查询上传excel规则
			getRule() {
				this.ApiRequestPost('/api/mall/ebactivity/print-format/get-annex-bill-type-rule', {})
					.then(result => {
							console.log(result, "附件");
							this.uploadRule = result.obj;
							this.fileRules = result.obj[0].rule;
							this.annexList = [{
								"fRuleID": result.obj[0].rule.fRuleID,
								"fAnnexTypeID": result.obj[0].rule.fAnnexTypeID,
								"fPath": result.obj[0].rule.fPath,
								"fSourceFileName": result.obj[0].rule.fSourceFileName,
								"fFileExName": result.obj[0].rule.fFileExName
							}]
							this.uploadServerPath = result.obj[0].uploadServerPath;
							this.dataUpdoce = {
								"token": result.obj[0].token
							};
							console.log(result, this.uploadServerPath, this.annexList, 'res');
						},
						rej => {
							this.formLoading = false;
							this.dialogFormVisible = false
						})
			},
			//下载派车模板
			downExcel() {
				window.open('https://www.henghesoft.com:8444/api/henghe/blob/2bdda0129891ffcaa64839fb38fb1d4a.xls', '_self')
			},
			//上传excel成功
			getDataSu(data, url) {
				console.log('data', data)
				console.log('url', url)
				this.ApiRequestPost('api/mall/ebcustomer/transporttool/import-excl', {
					fileName: data[0].fPath
				}).then(
					result => {
						console.log('result', result);
						this.chooseConfirmCar = result.obj;
					},
					rej => {}
				);
			},
			// 车船号 小写字符转大写
			blurInput() {
				this.addCarForm.fPlateNumber = this.checkStrWord(this.addCarForm.fPlateNumber)
			},
			checkStrWord(str) {
				if (str != '' && str != null) {
					// 转字母大写
					str = str.toUpperCase();
					// 去除空格 弃用
					str = str.replace(/\s+/g, "");
					return str;
				}
			},
			hiddClick() {
				console.log('1212121');
				this.dialogAddCar = false;
				this.resetAddCarFrom();
			},
			//查询最小的派车量
			acquireAmount(){
				this.ApiRequestPost('api/mall/ebbase/para-value/get-delivery-order-min-amount', {}).then(
					result => {
						this.minAmount = result
					},
					rej => {}
				);
			},
			//查询所有的车辆分组类别
			getgroupType() {
				this.ApiRequestPost('api/mall/ebcustomer/transporttool/get-list-groupName', {}).then(
					result => {
						console.log('分组信息列表===================',result)
						this.carToolType = []
						this.carToolList = []
						console.log(result)
						// result.obj.unshift('全部')
						let list = result.obj.reslist
						list.unshift({
							key : `全部(${result.obj.fSumcount})`,
							value : '全部',
						})
						this.carToolList = list
						list.map(item=>{
							this.carToolType.push(item.value)	
						})
						
						// this.carToolType = list[0].value
						console.log(this.carToolType)
						// if(this.addCarForm.carToolType == ""){
						// 	this.addCarForm.carToolType = this.carToolList[0].fTransportToolTypeID
						// }
					},
					rej => {}
				);
			},
			//查询所有的车辆分组类别
			getgroupTypes() {
				this.ApiRequestPost('api/mall/ebcustomer/transporttool/get-list-groupName', {}).then(
					result => {
						console.log('分组信息列表===================',result)
						this.carGroupList = result.obj.reslist;
						// this.carToolList.unshift('全部')
						// if(this.addCarForm.carToolType == ""){
						// 	this.addCarForm.carToolType = this.carGroupList[0]
						// }
					},
					rej => {}
				);
			},
			submitForm() {
				if (this.addCarForm.fMaximumLoad == 0) {
					this.tipsInfo("载货量不可为空")
					return;
				}
				// this.addCarLoading = true;
				this.$refs["addCarForm"].validate((valid) => {
					if (valid) {
						console.log(this.addCarForm.carGroupType)
						this.disabledAddCar = true
						this.ApiRequestPost('api/mall/ebcustomer/transporttool/create', {
							fGroupName:this.addCarForm.carGroupType,
							fTransportToolTypeID: this.addCarForm.carToolType,
							fMaximumLoad: this.addCarForm.fMaximumLoad,
							fPlateNumber: this.addCarForm.fPlateNumber,
							fLinkMan: this.addCarForm.fLinkMan,
							fLinkManTelCode: this.addCarForm.fLinkManTelCode,
							fTransportToolPlateColorID: this.addCarForm.TransportToolPlateColor,
							fStatus: 0,
							fRemark: ""
						}).then(
							result => {
								this.disabledAddCar = false
								console.log(result)
								// 派车车辆查询
								if(result){
									// this.resetAddCarFrom();
									// this.getTransportgroupList()
									// this.getgroupTypes()
									// this.getgroupType()
									if(this.carToolType == '-1' || !this.carToolType){
										this.getTransporttoolList()
										this.getgroupType()
									}
									this.getTransporttoolList(this.carToolType)
									this.getgroupType()
								}
								this.dialogAddCar = false;
								this.resetAddCarFrom()
								// this.addCarLoading = false;
							},
							rej => {
								this.disabledAddCar = false
								this.dialogAddCar = false;
								this.resetAddCarFrom()
								// this.addCarLoading = false;
							}
						);
					} else {
						this.disabledAddCar = false
						//console.log('error submit!!');
						return false;
					}
				});
			},
			resetAddCarFrom() {
				this.addCarForm = {
					carGroupType:'',//分组名
					carToolType: '', //类型
					fPlateNumber: '', //车号
					fLinkMan: '', //司机
					TransportToolPlateColor: 2,//车牌颜色
					fLinkManTelCode: '',
					fMaximumLoad: ''
				}
			},
			//查询所有启用运输工具类型
			// getTransportToolType() {
			// 	this.ApiRequestPost('api/mall/ebbase/transportTool-Type/get-onstatus-list', {}).then(
			// 		result => {
			// 			this.carToolList = result.obj.items;
			// 		},
			// 		rej => {}
			// 	);
			// },
			// 移除已选车辆
			deleteChoose(item) {
				this.chooseConfirmCar.splice(
					this.chooseConfirmCar.indexOf(this.chooseConfirmCar.find(
						function(element) {
							return element.fTransportToolID === item.fTransportToolID;
						})),
					1);
				this.chooseCar.splice(
					this.chooseCar.indexOf(this.chooseCar.find(
						function(element) {
							return element.fTransportToolID === item.fTransportToolID;
						})),
					1);
			},
			fMaximumLoadInput(val, index) {
				if (val.toString().includes('-')) {
					this.chooseConfirmCar[index].fMaximumLoad = Number(val.toString().replace(/\-/g, ''));
				}
			},
			fMaximumLoadChange(val, index) {
				this.chooseConfirmCar[index].fMaximumLoad = parseFloat(val);
			},
			//新增
			fFrequencyInput(val, index) {
				console.log(val)
				this.chooseConfirmCar[index].fFrequency = val.toString().replace(/[^\d]/g, '');
			},
			fFrequencyChange(val, index) {
				if(isNaN(val) || val == ''){
					console.log(val)
					val = 1
				}else{
					val = val
				}
				console.log(val)
				this.chooseConfirmCar[index].fFrequency = parseInt(val);
			},
			chooseConfirm() {
				let array = JSON.parse(JSON.stringify(this.chooseCar))
				array.map(item=>{
					item.fFrequency = 1
				})
				if (this.chooseConfirmCar.length < 1) {
					console.log(array)
					this.chooseConfirmCar = array
				} else {
					this.chooseConfirmCar.forEach(item => {
						array.splice(
							array.indexOf(array.find(
								function(element) {
									return element.fTransportToolID === item.fTransportToolID;
								})),
							1);
					})
					console.log(array)
					if (array.length > 0) {
						array.forEach(item => {
							this.chooseConfirmCar.push(item)
						})
					}
				}
				this.dialogChsCar = false;
			},
			// 全选
			handleSelectionAll(val) {
				this.chooseCar = val
			},
			// 选择
			handleSelectionChange(val) {
				this.chooseCar = val
			},
			// 订单信息
			getOrderInfo() {
				this.ApiRequestPostNOMess("api/mall/ebsale/order/get", {
					id: this.orderID
				}).then(res => {
					res.obj.fEndDate = this.getDate(res.obj.fEndDate);
					res.obj.fDeliveryAddress = res.obj.fDeliveryAddress.replace(/\\/g, '');
					res.obj.fPlanAmount = res.obj.fPlanAmount.toFixed(2);
					res.obj.fScheduleVanAmount = res.obj.fScheduleVanAmount.toFixed(2);
					res.obj.fReceivedAmount = res.obj.fReceivedAmount.toFixed(2);
					res.obj.sendCarAmount = (res.obj.fPlanAmount - res.obj.fScheduleVanAmount).toFixed(2);
					this.orderInfo = res.obj
					this.fProjectName=this.orderInfo.fProjectName;
				}).catch(err => {

				})
			},
			// 派车初始化接口
			deliverOrderInit() {
				this.ApiRequestPostNOMess("api/mall/ebsale/deliver-order/init", {
					id: this.orderID
				}).then(res => {
					this.initData = res.obj
				}).catch(err => {

				})
			},
			
			
			getTips() {
				const message = this.$message({
					dangerouslyUseHTMLString: true,
					type: 'success',
					showClose: true,
					duration: 3000,
					customClass: 'iconshopcar',
					message: `<span style="font-size:16px;color:#999999">已成功派车！</span>
										  <p style="margin-top:20px;color:#999999">您可以<a class="aselce" style="color:dodgerblue;cursor: pointer;">继续派车</a>，或<a href="#/SendCarDetail"  class="aselc" style="color:dodgerblue;cursor: pointer;">查看派车单</a></p>`
				});
				message.$el.querySelector('.aselce').onclick = () => {
					message.close();
				};
				message.$el.querySelector('.aselc').onclick = () => {
					message.close();
				};
			},
			// 派车
			async sendCarSubmit() {
				let array = []
				let MaximumLoad = 0;
				let isOverstep = false;
				console.log(this.chooseConfirmCar)
				let subFalg = true
				// // 工程是否为空
				if (this.fProjectName == '' || this.fProjectName == null || this.fProjectName == undefined) {
					this.tipsInfo('工程不可为空')
					return
				}
				await this.chooseConfirmCar.forEach(item => {

					// 司机是否为空
					if (item.fLinkMan == '' || item.fLinkMan == null || item.fLinkMan == undefined) {
						this.tipsInfo('司机不可为空')
						return;
					}
					// 电话是否为空
					if (item.fLinkManTelCode == '' || item.fLinkManTelCode == null || item.fLinkManTelCode == undefined) {
						this.tipsInfo('电话不可为空')
						return;
					}
					// 载货量是否为空
					if (item.fMaximumLoad == '' || item.fMaximumLoad == null || item.fMaximumLoad == undefined) {
						this.tipsInfo('载货量不可为空')
						return;
					}
					// 次数是否为空
					if (item.fFrequency == '' || item.fFrequency == null || item.fFrequency == undefined) {
						this.tipsInfo('次数不可为空')
						return;
					}
					// 载货量是否为0
					if (item.fMaximumLoad == 0) {
						this.tipsInfo('载货量不可为0')
						return;
					}
					MaximumLoad = (MaximumLoad*1000 + item.fMaximumLoad*1000)/1000;
					console.log(MaximumLoad,this.orderInfo.sendCarAmount)
					if (MaximumLoad > this.orderInfo.sendCarAmount) {
						isOverstep = true;
						// return;
					}
					array.push({
						fTransportToolID:item.fTransportToolID,
						fTransportToolPlateColorID: item.fTransportToolPlateColorID,
						fPlateNumber: item.fPlateNumber,
						fDriver: item.fLinkMan,
						fDriverPhone: item.fLinkManTelCode,
						fScheduleVanAmount: item.fMaximumLoad,
						fDescription:this.fProjectName,
						fFrequency : item.fFrequency
					})
				})
				if (array.length == 0) {
					return;
				}
				for(let item of this.chooseConfirmCar){
					if (item.fMaximumLoad < this.minAmount) {
						let targetIndex = ''
						targetIndex = this.chooseConfirmCar.indexOf(item) + 1
						this.tipsInfo('存在派车量小于最小派车量的派车单，最小派车量为'+this.minAmount+ this.orderInfo.fUnitName)
						subFalg = false
						return
					}
				}
				if(subFalg == false){
					return
				}
				if (isOverstep) {
					this.tipsInfo('当前派车量大于可派车量！');
					return;
				}
				this.sendCarloading = true
				this.ApiRequestPostNOMess("api/mall/ebsale/deliver-order/create-new", {
					fOrderID: this.orderID,
					detail: array,
					fIsContinue:this.orderFIsContinue,
				}).then(
					res => {
						this.sendCarloading = false
						if(res.obj.fIsErr == 1){
							this.orderFIsContinue = 1
							this.$confirm(res.obj.fErrMessage, '温馨提示', {
									  confirmButtonText: '继续派车',
									  cancelButtonText: '更换车辆',
									  type: 'warning'
									}).then(() => {
										this.sendCarSubmit()
										//派车时车辆属于监控规则设置内
									}).catch(() => {    
										this.orderFIsContinue = 0
									});
						}else{
							this.orderFIsContinue = 0
							//派车时车辆属于监控规则设置内
							if (res.obj.fList.length < 1) {
								this.tipsInfo(res.message);
							} else {
								console.log(res.obj.fList[0].fDeliveryOrderID)
								this.$store.commit("changeCommonData", {
									id: res.obj.fList[0].fDeliveryOrderID
								});
								// 获取订单信息
								this.getOrderInfo();
								// 派车初始化接口
								this.deliverOrderInit();
								// 派车车辆查询
								this.getTransporttoolList();
								// this.getTips();
								// this.$router.go(-1)
								if (res.obj.fList.length == 1) {
									this.$router.push({
										name: "SendCarSuccess",
										query: {
											obj: JSON.stringify(res.obj.fList[0])
										}
									})
								} else {
									this.$router.push({
										name: "SendCarSuccess",
										query: {
											obj: '{}'
										}
									})
								}
							}
						}
					},
					rej => {
						this.sendCarloading = false
					}
				).catch(err => {
					this.sendCarloading = false
				})
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
		font-weight: bold !important;
	}
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.pointer-color-theme:hover {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.btn-box {
		width: 160px;
		height: 40px;
		line-height: 40px;
	}
	

	.btn-box1 {
		width: 120px;
		height: 40px;
		line-height: 40px;
	}

	.send-car-item {
		width: 500px;
		// height: 30px;
		border: 1px solid #f2f2f2;
		border-radius: 5px;
		padding: 10px;
	}

	.send-car-item-fill {
		width: 362px;
		height: 76px;
	}

	.width100 {
		width: 100%;
	}

	.el-input ::v-deep .el-input-group__append,
	.el-input-group__prepend {
		padding: 0;
	}

	.add-car-btn {
		min-width: 110px;
		height: 30px;
		border-radius: 4px;
	}
	
	.padding-6{
		padding: 6px 0;
	}
	
	.flex{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		.el-input{
			width: 130px;
		}
	}

	.table-btn {
		width: 60px;
		height: 30px;
	}

	::v-deep .el-form-item .el-input__inner {
		width: 380px;
	}

	.input-x .el-input__inner {
		height: 23px;
	}

	.Sendcar-box {
		height: 100%;
		width: 100%;
		max-width: 1100px;
		min-width: 1100px;
		margin: 0 auto;
	}

	::v-deep .el-input__inner {
		height: 32px;
		line-height: 20px;
	}

	::v-deep .el-dialog__body {
		padding: 0px 20px;
	}

	::v-deep .el-input__icon {
		line-height: 32px;
	}

	::v-deep .el-button {
		padding: 4px 6px;
	}

	::v-deep .el-table .cell {
		padding-right: 10px;
	}

	::v-deep th {
		font-weight: 500;
		color: #000000;
	}

	::v-deep element.style {
		width: 100%;
		margin-top: 0px;
	}

	::v-deep .el-table .cell {
		padding-right: 0px;
	}

	::v-deep.el-table .cell,
	.el-table--border td:first-child .cell,
	.el-table--border th:first-child .cell {
		padding-left: 0px;
	}

	::v-deep .el-table td,
	::v-deep .el-table th {
		padding: 5px 0px;
		text-align: center;
	}

	.el-table th,
	.el-table tr {
		background-color: #CCCCCC;
	}

	.el-table thead {
		color: #000000;
		// font-weight: 500;
	}

	.SendCar-box1 {
		margin-top: 10px;
	}

	.SendCar-title {
		width: 505px;
		height: 107px;
		background-color:#EDFAFC;
		// background: linear-gradient(45deg, #E8FDFF, #EAEFFF, #E8FDFF, );
		// background: url(../../assets/sendCar/pcbg.png) 100% 100% no-repeat;
		// background-size: contain;
		// float: left;
		// padding-left: 15px;
	}

	.SendCar-title p:first-child {
		font-size: 14px;
		font-weight: 400;
		color: #000000;
	}

	.SendCar-title p:last-of-type {
		font-size: 22px;
		font-weight: bold;
		margin-left: 36px;
		margin-top: 5px;
	}

	.width-120 {
		width: 160px;
	}

	.Sendcar-right {
		width: 505px;
		height: 107px;
		background-color:#F1FBFE;
		// border: 1px solid rgba(242, 242, 242, 1);
		// background: url(../../assets/sendCar/pcbg1.png) 100% 100% no-repeat;
		// background-size: contain;
		float: right;
	}

	.width-505 {
		width: 430px;
	}

	.deletIconBtn {
		font-size: 16px;
		font-color: #000;
		font-weight: bolder;
	}

	.Sendcar-right p:first-child {
		color: #666666;
		margin: 15px 0px 7px 15px;
	}

	.Sendcar-right p:nth-child(2) {
		font-size: 14px;
		color: #000000;
		margin: 0px 0px 0px 41px;
		line-height: 24px;
	}

	.Sendcar-right p:last-of-type {
		font-size: 14px;
		color: #000000;
		margin-left: 41px;
	}

	.SensCar-check {
		font-size: 15px;
		font-weight: bold;
		width: 80px;
		// padding:23px 0px 9px 0px ;
		// margin-top:23px;
		line-height: 30px;
		margin-left: 13px;
	}

	.sendcar-bottom {
		width: 1100px;
		// height: 290px;
		// border: 1px solid rgba(242, 242, 242, 1);
	}

	.sendcar-conone {
		border: 1px solid rgba(242, 242, 242, 1);
		height: 290px;
	}

	.sendcar-conone img {
		margin-left: 451px;
	}

	.sendcar-conone p {
		font-size: 15px;
		// font-weight: bold;
		color: #cccccc;
		text-align: center;
	}

	.sendcar-conone span {
		color: #0173FE;
		border-bottom: 1px solid #0173FE;
	}

	.Sendcar-footer,
	.Sendcar-footerTwo {
		// width: 1099px;
		height: 69px;
		background: #f8f9fe;
		margin-top: 10px;
	}

	.Sendcar-footer div {
		width: 120px;
		height: 40px;
		background-color: #2E66F8;
		color: #FFFFFF;
		float: right;
		margin: 15px 20px 0px 0px;
		text-align: center;
		line-height: 40px;
	}

	.carname,
	.weight {
		display: flex;
	}

	.Sendcar-footerTwo {
		display: flex;
	}

	// .Sendcar-footerTwo p:nth-child(1) {
	// 	font-size: 14px;
	// 	color: #666874;
	// 	line-height: 69px;
	// 	margin-left: 13px;
	// 	margin-right: 520px;
	// }

	// .Sendcar-footerTwo p:nth-child(2) {
	// 	font-size: 14px;
	// 	color: #666874;
	// 	line-height: 69px;
	// 	margin-left: 10px;
	// }

	// .Sendcar-footerTwo div {
	// 	width: 120px;
	// 	height: 40px;
	// 	text-align: center;
	// 	line-height: 40px;
	// 	margin-right: 5px;
	// 	margin-top: 15px;
	// 	margin-left: 10px;
	// 	border-radius: 2px;
	// }

	// .Sendcar-footerTwo div:first-of-type {
	// 	border: 1px solid #0173FE;
	// 	color: #0173FE;
	// 	background-color: #fff;
	// }

	// .Sendcar-footerTwo div:last-of-type {
	// 	background-color: #0173FE;
	// 	color: #FFFFFF;
	// }

	.carname p {
		font-size: 14px;
		color: #000000;
		margin: 5px 5px 5px 20px;
	}

	.weight p {
		font-size: 12px;
		color: #999999;
		margin-left: 20px;
	}

	.carInfor {
		border-bottom: 1px solid #F2F2F2;
		padding-bottom: 8px;
	}

	.list-radio {
		position: relative;
	}

	.rowlist-radio {
		position: absolute;
		left: 0;
		top: 20px;
	}


	.carInfor1 {
		// width: calc(45% - 9px);
		// width: -webkit-calc(45% - 9px);
		// width: -moz-calc(45% - 9px);
		width: 350px;
		height: 73px;
		box-sizing: border-box;
		// display: inline-block;
		background-color: #fff;
		padding: 15px 16px;
		border-radius: 10px;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
		margin-bottom: 15px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		float: left;
		margin-right: 16px;
	}

	.carnamespan {
		min-width: 67px;
		margin-right: 10px;
	}

	.carname1 {
		color: #000;
		font-weight: bold;
		font-size: 14px;
	}

	.carname1 span,
	.weight1 {
		display: block;
	}

	.carname1 span {
		margin-right: 10px;
		vertical-align: middle;
	}

	.weight1 {
		font-size: 12px;
		color: #666;
	}

	.editicon .u-icon:last-child {
		margin-left: 10px;
		margin-right: -2.5px;
	}

	.cartype1 {
		display: inline-block;
		width: 60px;
		color: #0173FE;
		font-size: 12px;
		font-weight: 400;
	}

	.editIconBtn {
		margin-right: 22px;
	}

	.flex-row {
		display: flex;
	}

	// /deep/ .el-form-item__label {
	// 	text-align: left;
	// }

	// /* 超出宽度省略号 */
	// .rowline-show {
	// 	max-width: 100px;
	// 	word-break: break-all;
	// 	white-space: nowrap;
	// 	overflow: hidden; //隐藏溢出内容
	// 	text-overflow: ellipsis; //超出的内容显示省略号
	// }
	.font-size15{
		font-size: 15px;
	}
	.OrderText{
		// line-height: 35px;
		color: #FF3333;
	}
	a:hover{
		border-bottom: 1px solid #0173FE;
	}
</style>

<template>
	<div class="upload-img-list" :class="themeClass">
		<!-- <div v-for="value in showList" :key="value.id">
			<div class="divdoc">
				<div style="display:flex;padding:5px">

					<div style="padding:5px" class="lineone"><span class="lineone"><a :href="value.url">{{value.fSourceFileName}}</a></span></div>
				</div>

			</div>
		</div> -->
		<el-upload class="upload-img-item upload-img-add" :action="uploadActionURL" :show-file-list="false" :on-success="uploadSuccessDoc"
		 :disabled="false" accept=".xls,.xlsx" :before-upload="handleBeforeUploadDoc" :data="uploadToken"
		 list-type="picture">
			<!-- <i class="el-icon-folder" style="margin-left: 10px;margin-top: 6px;font-size: 24px;"></i> -->
			<div class="border-color-theme color-theme text-center btn-box margin-r-10 pointer">
				导入Excel
			</div>
		</el-upload>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import uploadFile from "../../utils/uploadFile";
	export default {
		name: "UploadX",
		components: {

		},
		data() {
			return {
				annexServerUrl: '',
				uploadFileAshx: "",
			}
		},
		props: {
			// v-model
			value: {
				type: Array,
				required: false,
			},
			rules: { //上传图片规则
				type: Object,
				default: {}
			},
			uploadToken: { //上传图片的token
				type: Object,
				default: {}
			},
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			uploadActionURL() {
				return uploadFile.makeUploadActionURL(this.uploadDir, {
					ashx: this.uploadFileAshx,
				});
			},
			showList() {
				if (this.value) {
					let indexstr = this.uploadActionURL.lastIndexOf("/");
					let lefturl = this.uploadActionURL.substr(0, indexstr + 1);
					this.value.forEach((item) => {
						let urlpght = "http://";
						if (item.fPath != "") {
							if ((item.fPath.indexOf(urlpght)) == -1) {
								item.url = lefturl + item.fPath;
							} else {
								item.url = item.fPath;
							}
						} else {
							item.url = "";
						}
					});
					var tempv = this.value.map((i, index) => ({
						id: i.url,
						url: i.url,
						fSourceFileName: i.fSourceFileName,
						index,
					}));
					return tempv;
				}
				return [];
			},
		},

		methods: {
			uploadSuccessDoc(response, file) {
				const result = uploadFile.parseResult(response, this.uploadFileAshx);

				if (this.value) {
					const data = this.value.concat([{
						url: result.url,
						fAnnexTypeID: this.rules.fAnnexTypeID,
						ruleId: this.rules.fRuleID,
						fPath: response.fileName,
						fSourceFileName: file.name,
						fFileExName: file.name.split('.')[0]
					}, ]);
					console.log('data1', data);
					this.$emit("input", data);
					this.$emit("success", data, this.annexServerUrl);
				} else {

					const data = [{
						url: result.url,
						fAnnexTypeID: this.rules.fAnnexTypeID,
						ruleId: this.rules.fRuleID,
						fPath: response.fileName,
						fSourceFileName: file.name,
						fFileExName: file.name.split('.')[0]
					}, ];
					console.log('data2', data);
					this.$emit("input", data);
					this.$emit("success", data, this.annexServerUrl);
				}
			},
			handleBeforeUploadDoc(file) {
				console.log("file", file);
				let uploadRule = this.rules;
				let imgWidth = "";
				let imgHight = "";
				let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
				let uploadType = uploadRule.fFileType.indexOf(extension);
				let uploadSize = file.size / 1024 / 1024;

				if (uploadType == -1) {
					this.$message.warning("只能上传" + uploadRule.fFileType + "文件");
					return false;
				}
				if (uploadSize > uploadRule.fMaxSize) {
					this.$message.error("上传大小不能超过" + uploadRule.fMaxSize + " MB!");
					return false;
				}
				const _self = this;
				let maxwidth = this.rules.fMaxWidth;
				let maxheight = this.rules.fMaxHeight;
				let flagwidth = false;
				let flagheight = false;
				if (this.rules.fMaxWidth != '' && this.rules.fMaxWidth != 0 && this.rules.fMaxWidth != null) {
					flagwidth = true;
				}
				if (this.rules.fMaxHeight != '' && this.rules.fMaxHeight != 0 && this.rules.fMaxHeight != null) {
					flagheight = true;
				}

			},
			async getImgUrl() {
				//上传图片前缀
				this.ApiRequestPostNOMess(
					"/api/mall/ebbase/para-value/get-config"
				).then(
					(result) => {
						this.annexServerUrl = result.obj.annexServerUrl
						this.uploadFileAshx = result.obj.annexServerUrl + 'upload';

					},
					(rej) => {}
				);
			},

		},
		async mounted() {
			await this.getImgUrl();
		},
	}
</script>

<style lang="scss" scoped="scoped">
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}
	
	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.btn-box {
		width: 160px;
		height: 40px;
		line-height: 40px;
	}

	.divdoc {

		/* width: 90%; */
		/* padding: 5px; */
		font-size: 13px;
		background-color: #fff;
	}

	.divload {

		width: 100%;
		text-align: right
	}

	.lineone {
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}
</style>
